<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Avans</h3>

					<p class='text-lg'>
                        Avans Tarihi, Tutar alanları zorunludur.<br>
                        <br>
                        Çalışma ayı içerisinde gireceğiniz avans tutarı maaşdan fazla olamaz. Maaşdan fazla girmeye çalıştığınızda sistem izin vermeyecektir.<br>
                        <br>
                        Onaylanan gönderilmiş avanslar silinemez. Silmeniz gereken durumlarda muhasebe ile irtibata geçip onay ve ödeme durumlarının pasif edilmesini istemeniz gerekmektedir. Maaş hesaplaması yapılmış ise bu işlemi yapmanız kesinlikle önerilmez.<br>
                        <br>
                        Maaş hesaplaması yapılmış ise yeni avans ekleme yapamazsınız veya silemezsiniz.<br>
                        <br>
                        Maaş değişikliği bekleyen personelde avans ekleme yapamazsınız.<br>
                        <br>
                        Sadece çalışma takviminde avans girmelisiniz.<br>
                        <br>
					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Avans Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='startDate' dateFormat='dd.mm.yy' autocomplete='off'  :showIcon='true' :showButtonBar='true' v-model='allowance.allowanceDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Avans Tutarı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='dayCount' autocomplete='off'  v-model='allowance.totalPrice' showButtons></InputNumber>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Avans Tipi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='type' v-model='allowance.type' :options='typeList' optionLabel='label'
                                      option-value='value' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='comment' type='text' autocomplete='off' v-model='allowance.comment' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save' v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                    <div class='mt-5'>
                        <Toolbar class='mb-3 p-3'>
                            <template v-slot:start>
                                <div class='my-0'>
                                    <h5 class='mb-0'>
                                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true' :showButtonBar='true' v-model='selectedSalaryDate' @date-select='salaryDateChanged' class="mr-2"></Calendar>
                                    </h5>
                                </div>
                                <div>
                                    <Button id='save' label='Tümünü Göster' icon='pi pi-check' @click='showAllAllowance' v-bind:disabled='isProcessing'></Button>
                                </div>
                            </template>
                        </Toolbar>

                        <DataTable :value='allowanceList' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                            <template #empty>
                                Kayıtlı avans bulunamadı.
                            </template>

                            <template #loading>
                                Avanslar yükleniyor. Lütfen bekleyin.
                            </template>

                            <Column field='startDate' header='Avans Tarihi' style='width:10rem'>
                                <template #body='slotProps'>
                                    {{ formatDate(slotProps.data.allowanceDate) }}
                                </template>
                            </Column>

                            <Column field='totalPrice' header='Kesilen Ücret' style='width:10rem'>
                                <template #body='{data}'>
                                    {{ formatPrice(data.totalPrice) }} &#8378;
                                </template>
                            </Column>
                            <Column field='type' header='Avans Tipi'>
                            </Column>
                            <Column field='comment' header='Açıklama'>
                            </Column>

                            <Column field='verified' header='Onay Durumu' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                                <template #body='{data}'>
                                    <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}"></i>
                                </template>
                            </Column>

                            <Column field='isPaid' header='Ödeme Durumu' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                                <template #body='{data}'>
                                    <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isPaid, 'text-pink-500 pi-times-circle': !data.isPaid}"></i>
                                </template>
                            </Column>

                            <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-trash' class='p-button-rounded p-button-primary' @click='deleteAllowance(slotProps.data)' />
                                </template>
                            </Column>

                        </DataTable>
                    </div>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import {
    checkEmployeeSalaryCalculated, checkEmployeeSalaryChanged,
    checkEmployeeStillActive,
    getEmployee,
    getProjectEmployee,
} from '../common/commonFunctions';
import EmployeeAllowanceService from '../../services/employeeAllowanceService';
import { checkActiveProjectIdIsValid, getActiveProjectId, normalizeDate } from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _employeeAllowanceService: null,
    _smsService: null,

    created() {
        this._smsService = new SmsService();
        this._employeeService = new EmployeeService();
        this._employeeAllowanceService = new EmployeeAllowanceService();
        this._projectEmployeeService = new ProjectEmployeeService();

        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();

        this.selectedSalaryDate = moment().toDate();
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
    },

    async mounted() {
        this.employee = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.getEmployeeAllowanceList();
    },
    data() {

        return {
            isProcessing: false,
            allowance: {
                startDate: null,
                endDate: null,
                dayCount: 0,
                isPriceCut: true,
                type: '-1',
                comment: '',
                totalPrice: 0,
            },
            year: 1900,
            month: 1,
            selectedSalaryDate: null,
            loadingIndicator: false,
            employeeId: 0,
            projectEmployeeId: 0,
            allowanceList: [],
            projectEmployee: {
                officialSalary: 0,
                unOfficialSalary: 0,
            },
            employee: {},
            visibleLeft: false,
            typeList: [
                {
                    label: 'Lütfen Seçiniz',
                    value: '-1',
                },
                {
                    label: 'Resmi',
                    value: 'Resmi',
                },
                {
                    label: 'Diğer',
                    value: 'Diğer',
                }
            ],
        };
    },
    methods: {

        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            await this.getEmployeeAllowanceList();
        },
        async showAllAllowance() {
            this.loadingIndicator = true;
            await this.getEmployeeAllAllowanceList();
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                this.buildAllowanceCreateObject();

                const insertResponse = await this._employeeAllowanceService.insertEmployeeAllowance(this.allowance);
                if (insertResponse.isSuccess) {
                    await this.getEmployeeAllowanceList();
                    await this._smsService.sendEmployeeNewAllowanceMessage(this.projectEmployeeId, insertResponse.data);
                    this.resetForm();

                    showSuccessMessage(this, 'Avans Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Avans Güncellemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async validateForm() {

            if(this.allowance.type == "-1" || this.allowance.type == '' ||this.allowance.type == null)
            {
                showValidationMessage(this, 'Avans Tipi Seçilmelidir');
                return false;
            }
            if(!checkEmployeeSalaryChanged(this, this.projectEmployee))
            {
                return false;
            }

            if (this.allowance.allowanceDate == null || this.allowance.allowanceDate == '') {
                showValidationMessage(this, 'Avans Tarihi Seçilmelidir');
                return false;
            }

            if (this.allowance.totalPrice == '') {
                showValidationMessage(this, 'Avans Tutarı Girilmelidir');
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, this.allowance.allowanceDate, this.projectEmployeeId) == true) {
                return false;
            }

            if(await checkEmployeeStillActive(this, this.projectEmployeeId) == false)
            {
                return false;
            }

            if (await this.checkEmployeeAllowance() == false) {
                return false;
            }

            return true;
        },

        async checkEmployeeAllowance() {
            this.loadingIndicator = true;
            let startDate = moment(this.allowance.allowanceDate).startOf('month').toDate();
            let endDate = moment(this.allowance.allowanceDate).endOf('month').toDate();
            let dayOffResponse = await this._employeeAllowanceService.checkEmployeeAllowances(this.projectEmployeeId, startDate, endDate, this.allowance.totalPrice, this.allowance.type);
            this.loadingIndicator = false;
            if (dayOffResponse.isSuccess) {
                if(dayOffResponse.data == false)
                {
                    showErrorMessage(this, 'Personel Maşından Fazla Avans Kullanılamaz');
                }
                return dayOffResponse.data;
            } else {
                showErrorMessage(this, 'Personel Maaşından Fazla Avans Kullanılamaz');
                return false;
            }

        },
        async getEmployeeAllowanceList() {
            this.loadingIndicator = true;
            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let allowanceResponse = await this._employeeAllowanceService.getEmployeeAllowances(this.projectEmployeeId, startDate, endDate);
            if (allowanceResponse.isSuccess) {
                this.allowanceList = allowanceResponse.data;
            }
            this.loadingIndicator = false;
        },

        async getEmployeeAllAllowanceList() {
            this.loadingIndicator = true;
            let allowanceResponse = await this._employeeAllowanceService.getEmployeesAllAllowances(this.projectEmployeeId);
            if (allowanceResponse.isSuccess) {
                this.allowanceList = allowanceResponse.data;
            }
            this.loadingIndicator = false;
        },

        async deleteAllowance(item) {
            if (await checkEmployeeSalaryCalculated(this, item.allowanceDate, this.projectEmployeeId) == true) {
                return false;
            }

            this.$confirm.require({
                message: 'Avansı Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    if (item.isApproved || item.isPaid) {
                        showErrorMessage(this, 'Bu Avans Onaylı Yada Ödenmiş. Bu Yüzden Silemezsiniz');
                        return;
                    }
                    let deleteResponse = await this._employeeAllowanceService.deleteEmployeeAllowance(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getEmployeeAllowanceList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                },
            });
        },
        buildAllowanceCreateObject() {
            this.allowance.allowanceDate = normalizeDate(this.allowance.allowanceDate);
            this.allowance.projectId = this.projectEmployee.projectId;
            this.allowance.employeeId = this.employeeId;
            this.allowance.isActive = true;
            this.allowance.isApproved = false;
            this.allowance.iban = this.projectEmployee.iban;
            this.allowance.bankName = this.projectEmployee.bankName;
            this.allowance.projectEmployeeId = this.projectEmployeeId;
        },
        resetForm() {
            this.allowance.projectId = this.projectEmployee.projectId;
            this.allowance.employeeId = this.employeeId;
            this.allowance.allowanceDate = null;
            this.allowance.comment = '';
            this.allowance.totalPrice = 0;
            this.allowance.isActive = true;
            this.allowance.isApproved = false;
            this.allowance.iBan = '';
            this.allowance.bankName = '';
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
};

</script>


<style scoped>

</style>
