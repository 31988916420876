import { deleteDataById, getData, getExcel, getPaginatedData, insertData, patchData } from './commonService';

export default class EmployeeAllowanceService {

    #defaultUrl = '/api/EmployeeAllowance';
    #defaultResourceName = 'Avans';

    async insertEmployeeAllowance(dataItem) {
        const createData = {
            "employeeId": dataItem.employeeId,
            "projectId": dataItem.projectId,
            "allowanceDate": dataItem.allowanceDate,
            "managerUserId": dataItem.managerUserId,
            "totalPrice": dataItem.totalPrice,
            "isActive": true,
            "isApproved": false,
            "comment": dataItem.comment,
            "iBan": dataItem.iban,
            "bankName": dataItem.bankName,
            "projectEmployeeId": dataItem.projectEmployeeId,
            "type": dataItem.type
        }


        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateAllowance(id, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "allowanceDate", "value": dataItem.allowanceDate},
            {"op": "replace", "path": "managerUserId", "value": dataItem.managerUserId},
            {"op": "replace", "path": "totalPrice", "value": dataItem.totalPrice},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "isApproved", "value": dataItem.isApproved},
            {"op": "replace", "path": "comment", "value": dataItem.comment},
        ];

        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);

    }

    async updateAllowanceApprovedStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isApproved", "value": newStatus}
        ];

        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);

    }

    async updateAllowancePaidStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isPaid", "value": newStatus}
        ];

        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);

    }

    async deleteEmployeeAllowance(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async checkEmployeeAllowances(projectEmployeeId, startDate, endDate, requestedPrice, type) {
        return await getData(this.#defaultUrl + '/check?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() + "&requestedAllowancePrice=" + requestedPrice +"&type="+type , this.#defaultResourceName);
    }

    async getEmployeeAllowances(projectEmployeeId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getEmployeesAllAllowances(projectEmployeeId) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId , this.#defaultResourceName);
    }

    async getAllAllowances(startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getAllAllowancesReport(startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectAllowancesReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectAllowances(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectAllowancesReportForAccounting(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectAllowancesForAccounting(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getAllAllowancesExcel(startDate, endDate) {
        return await getExcel(this.#defaultUrl + '/Excel?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectAllowancesExcel(projectId, startDate, endDate) {
        return await getExcel(this.#defaultUrl + '/Excel?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }
}